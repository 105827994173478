import { ExtendedPlatformContext } from '../../../../types/platformApi';
import { AppInstallOption } from '@wix/editor-platform-host-integration-apis';
import { BIReporter } from '../bi';
import {
  CODE_PACKAGE,
  EditorComponents,
  PlatformComponents,
} from '../constants';
import { installUnifiedComponents } from './installComponents';
import { AppUnifiedComponentData } from '@wix/document-services-types';
import {
  createUnifiedComponentsInstallationError,
  UnifiedComponentsInstallationErrorCode as ErrorType,
} from '../errors';
import {
  AddAppData,
  AppData,
  UnifiedComponentsCollection,
  UnifiedPage,
  UnifiedWidget,
} from '../../../../types/unifiedComponents';

function getUnifiedComponentsCollection(
  appData: AppData,
): Promise<UnifiedComponentsCollection> {
  const unifiedComponents: UnifiedComponentsCollection = {
    widgets: {},
    pages: {},
    codePackages: [],
    platformComponents: [],
  };

  return new Promise((resolve, reject) => {
    appData.components?.forEach(({ data, type, componentId }) => {
      if (!data) {
        return;
      }

      const { widget, page } =
        (data as AppUnifiedComponentData)?.installation ?? ({} as any);
      if (page && componentId) {
        unifiedComponents.pages[componentId] = data as UnifiedPage;
      } else if (widget && componentId) {
        unifiedComponents.widgets[componentId] = {
          ...data,
          type,
          widgetId: componentId,
        } as unknown as UnifiedWidget;
      } else if (type === CODE_PACKAGE) {
        unifiedComponents.codePackages.push(data);
      } else if (PlatformComponents.includes(type as string)) {
        unifiedComponents.platformComponents.push(data);
      } else if (EditorComponents.includes(type as string)) {
        return reject(
          createUnifiedComponentsInstallationError(
            ErrorType.nonUnifiedComponentInUnifiedApp,
            `The component ${componentId} is not in unified form`,
          )
            .withAppDefIds(appData.appDefinitionId)
            .withComponentGUID(componentId as string, 'non-unified-component'),
        );
      }
    });
    resolve(unifiedComponents);
  });
}

export function installSingleUnifiedComponentsApp(
  context: ExtendedPlatformContext,
  appData: AppData,
  biReporter: BIReporter,
  appsOptions?: AppInstallOption,
  finishInstallationCallback?: (appDefId: string, data: AddAppData) => void,
) {
  let collection: UnifiedComponentsCollection;

  const installAppUnifiedComponents = () =>
    installUnifiedComponents(context, biReporter, appData, collection);

  function installCodePackages(): Promise<void> {
    return biReporter.withBI(
      'INSTALL_CODE_PACKAGES',
      { appDefinitionId: appData.appDefinitionId },
      function insallCodePackagesIfNeeded() {
        return new Promise<void>((resolve, reject) => {
          if (collection.codePackages.length > 0) {
            context.documentServices.wixCode.codePackages
              .installCodeReusePkg(
                appData.appDefinitionId,
                appData?.appFields?.installedVersion as string,
              )
              .then(resolve)
              .catch((error) =>
                reject(
                  createUnifiedComponentsInstallationError(
                    ErrorType.addingCodePackagesFailed,
                    'Could not install code packages',
                  )
                    .withAppDefIds(appData.appDefinitionId)
                    .withParentError(error),
                ),
              );
          } else {
            resolve();
          }
        });
      },
    );
  }

  function runEditorScript(): Promise<void> {
    return biReporter.withBI(
      'PLATFORM_RUN_SCRIPTS',
      { appDefinitionId: appData.appDefinitionId },
      function runEditorScriptFromDM() {
        return new Promise<void>((resolve, reject) => {
          if (collection.platformComponents.length > 0) {
            context.documentServices.platform.installationFlow
              .addToDocumentWithEditorScript(appData.appDefinitionId, {
                appDefinitionId: appsOptions,
              })
              .then(resolve)
              .catch((error) =>
                reject(
                  createUnifiedComponentsInstallationError(
                    ErrorType.runningEditorScriptFailed,
                    'Could not run editor script',
                  )
                    .withAppDefIds(appData.appDefinitionId)
                    .withParentError(error),
                ),
              );
          } else {
            resolve();
          }
        });
      },
    );
  }

  // TODO: Remove ts-except-error when types will be up to date (EP-3956)
  function navigateToAppEndPage() {
    // TODO: Unskip test in `installFlow.spec.ts` once this logic will be aligned in schema
    return new Promise<void>((resolve, reject) => {
      // @ts-expect-error
      const { navigateAfterInstallation } = appData?.generalAppSettings ?? {};
      if (navigateAfterInstallation) {
        const resolveWhenNavigationDone = () => {
          resolve();
          biReporter.reportBI('NAVIGATE_TO_DEVCENTER', {
            appDefinitionId: appData.appDefinitionId,
          });
        };

        const rejectWhenNavigationFailed = () =>
          reject(
            createUnifiedComponentsInstallationError(
              ErrorType.navigateToAppEndPageFailed,
              'Could not fulfill required page navigation after installation',
            ).withAppDefIds(appData.appDefinitionId),
          );

        context.documentServices.pages.navigateTo(
          navigateAfterInstallation,
          resolveWhenNavigationDone,
          rejectWhenNavigationFailed,
        );
      } else {
        resolve();
      }
    });
  }

  // TODO: AddAppData - callback with relevant data (success \ type)
  function triggerAppInstalledCallback() {
    const addAppData = {
      ...appData,
      success: true,
      type: 'unified-app',
    };
    return new Promise<AddAppData>((resolve) => {
      finishInstallationCallback?.(appData.appDefinitionId, addAppData);
      resolve(addAppData);
    });
  }

  return new Promise<AddAppData>((resolve, reject) =>
    getUnifiedComponentsCollection(appData)
      .then((componentsCollection) => {
        collection = componentsCollection;
      })
      .then(installAppUnifiedComponents)
      .then(installCodePackages)
      .then(runEditorScript)
      .then(navigateToAppEndPage)
      .then(triggerAppInstalledCallback)
      .then(resolve)
      .catch(reject),
  );
}
