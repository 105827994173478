import { ExtendedPlatformContext } from '../../../../types/platformApi';
import { AppData, UnifiedWidget } from '../../../../types/unifiedComponents';
import {
  AddUnifiedComponentsErrorCode as ErrorCode,
  createAddUnifiedComponentsError,
} from '../errors';
import { CompRef } from '@wix/document-services-types';

const TOKEN = 'unified-components-installation';

const buildDefaultCustomElement = (
  layout: { width: number; height: number },
  {
    appDefinitionId,
    widgetId,
  }: {
    appDefinitionId: AppData['appDefinitionId'];
    widgetId: UnifiedWidget['widgetId'];
  },
) => ({
  componentType: 'wixui.CustomElementComponent',
  data: {
    appDefinitionId,
    widgetId,
    type: 'CustomElement',
    shouldReload: false,
    initialAttributes: '{}',
  },
  layout: {
    x: 0,
    y: 0,
    ...layout,
    fixedPosition: true,
  },
  config: {},
});

export async function addCustomElement(
  context: ExtendedPlatformContext,
  appData: AppData,
  widgetDefinition: UnifiedWidget,
  containerRef: CompRef,
): Promise<CompRef> {
  const { widgetId, size } = widgetDefinition;
  // const isStretched = !!widgetDefinition.isStretched?.desktop;

  const layout = {
    height:
      size?.height.heightMode === 'FIXED'
        ? size.height.fixedOptions.height
        : 500,
    width: size?.width.width ?? 500,
  };

  return new Promise<CompRef>((resolve, reject) => {
    context.platformApiMethods.document.components
      .add(appData, TOKEN, {
        componentDefinition: buildDefaultCustomElement(layout, {
          appDefinitionId: appData.appDefinitionId,
          widgetId,
        }),
        pageRef: containerRef,
      })
      .then((compRef: CompRef) => resolve(compRef))
      .catch((error: Error) => {
        reject(
          createAddUnifiedComponentsError(
            ErrorCode.addingCustomElementWidgetFailed,
            'Could not add custom element widget',
          )
            .withUserComponentId(widgetId, 'unified-widget')
            .withParentError(error),
        );
      });
  });
}
