import { CompRef, DocumentServicesObject } from '@wix/document-services-types';

const appWidget = 'platform.components.AppWidget';
const appController = 'platform.components.AppController';

const getAppFromController = (
  documentServices: DocumentServicesObject,
  controllerRef: CompRef,
) => {
  const { applicationId } =
    documentServices.components.data.get(controllerRef) || {};
  const { applicationId: actualApplicationId } =
    documentServices.tpa.app.getDataByAppDefId(applicationId) || {};
  return { applicationId: actualApplicationId, appDefinitionId: applicationId };
};

const getAppFromTPA = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
) => {
  const { applicationId, appDefinitionId } =
    documentServices.components.data.get(compRef) || {};
  return { applicationId, appDefinitionId };
};

export const getComponentApp = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): { appDefinitionId?: string; applicationId?: number } => {
  const compType = documentServices.components.getType(compRef);
  if (compType === appWidget || compType === appController) {
    return getAppFromController(documentServices, compRef);
  }
  const { controllerRef } =
    documentServices.platform.controllers.connections.getPrimaryConnection(
      compRef,
    ) || {};
  if (controllerRef) {
    return getAppFromController(documentServices, controllerRef as CompRef);
  } else {
    return getAppFromTPA(documentServices, compRef);
  }
};
