import { merge } from 'lodash';
import { PlatformApiEnhancerFunction } from '../../../types/platformApi';
import { canvasOverlayEnhancer } from './canvasOverlayEnhancer';
import { viewStateEnhancer } from './viewState';
import { toolsPanelEnhancer } from './toolsPanel';
import { pageReplaceEnhancer } from './pageReplace';

export const enhance: PlatformApiEnhancerFunction = (
  platformApiMethods,
  apis,
) => {
  return [
    viewStateEnhancer,
    canvasOverlayEnhancer,
    toolsPanelEnhancer,
    pageReplaceEnhancer,
  ].reduce((enhancedPlatformApiMethods, applyEnhancer) => {
    merge(enhancedPlatformApiMethods, applyEnhancer(platformApiMethods, apis));
    return enhancedPlatformApiMethods;
  }, platformApiMethods);
};
