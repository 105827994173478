import { DocumentServicesObject } from '@wix/document-services-types';
import { EventType } from '@wix/editor-platform-sdk-types';
import { componentSelectionChangedHandler } from './componentSelectionChanged';
import { IExperimentsApiAdapter } from '../../../utils/experimentsApiAdapter';
import { HostEventData } from './types';

export type EventData = {
  eventType: string;
  eventPayload?: object;
};

const getHostEventData = (event: string): HostEventData => {
  const emptyData: EventData = {
    eventType: '',
    eventPayload: {},
  };

  try {
    const data = JSON.parse(event) as { args?: EventData };

    const { eventType, eventPayload = {} } = data?.args ?? emptyData;

    return { eventType, ...eventPayload } as HostEventData;
  } catch {
    return {
      eventType: '',
    } as HostEventData;
  }
};

export const subscribeOnHostEvents = (
  documentServicesAPI: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
) => {
  documentServicesAPI.platform.subscribeOnCustomAppEvents((e) => {
    const hostEvent = getHostEventData(e);
    const eventType = hostEvent.eventType;

    switch (eventType) {
      case EventType.componentSelectionChanged:
        componentSelectionChangedHandler(
          documentServicesAPI,
          experimentsAPI,
          hostEvent,
        );
        break;
    }
  });
};
