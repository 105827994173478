import { CompRef, PageRef } from '@wix/document-services-types';
import { chainPromisesAsync } from '../utils';
import {
  MainPresets,
  UnifiedComponentsCollection,
  UnifiedPage,
  UnifiedWidget,
  WidgetAsContent,
  AppData,
} from '../../../../types/unifiedComponents';
import {
  createAddUnifiedComponentsError,
  AddUnifiedComponentsErrorCode as ErrorType,
} from '../errors';
import { ExtendedPlatformContext } from '../../../../types/platformApi';
import {
  BLOCKS_WIDGET_COMP_TYPE,
  CUSTOM_ELEMENT_COMP_TYPE,
  PAGE_REGIONS,
} from '../constants';
import { addBlocksClosedWidget } from './addBlocksWidget';
import { addCustomElement } from './addCustomElement';

const TOKEN = 'unified-components-installation';

const addWidgetHandler = {
  [BLOCKS_WIDGET_COMP_TYPE]: addBlocksClosedWidget,
  [CUSTOM_ELEMENT_COMP_TYPE]: addCustomElement,
};

type AllowedWidget = keyof typeof addWidgetHandler;

export function getAddUnifiedComponentsMethods(
  context: ExtendedPlatformContext,
  appData: AppData,
) {
  function createBlankPage(pageDefinition: UnifiedPage) {
    return new Promise<PageRef>((resolve, reject) => {
      const pageUriSEO = pageDefinition.installation?.page?.slug
        ?.split('/')
        .pop();
      if (!pageUriSEO) {
        // TODO: Should we create logic that define a default?
        reject(
          createAddUnifiedComponentsError(
            ErrorType.pageHaveNoSlugDefinition,
            `The page ${pageDefinition.base?.name} have no slug definition`,
          ),
        );
      }
      const definition = {
        data: {
          pageUriSEO,
          appDefinitionId: appData.appDefinitionId,
          managingAppDefId: appData.appDefinitionId,
        },
      };

      try {
        // Should use pages.add of DS instead - once studio migrate their logic to be covered in DS.
        context.platformApiMethods.document.pages
          .add(appData, TOKEN, {
            definition,
            title: pageDefinition.base?.name as string,
            shouldAddMenuItem: false,
            shouldNavigateToPage: false,
          })
          .then(resolve);
      } catch (error) {
        reject(error);
      }
    });
  }

  function addPage(
    pageDefinition: UnifiedPage,
    widgets: UnifiedComponentsCollection['widgets'],
  ): Promise<PageRef | undefined> {
    return new Promise((resolve, reject) => {
      if (!pageDefinition.installation?.base?.autoAdd) {
        return resolve(undefined);
      }

      // TODO: Do we want to throw error or to generate alternative slug?
      createBlankPage(pageDefinition)
        .then((thisPageRef) =>
          addContentToPage(pageDefinition, widgets, thisPageRef),
        )
        .then(resolve)
        .catch((error: Error) =>
          reject(
            createAddUnifiedComponentsError(
              ErrorType.creatingNewPageFailed,
              `Failed to create ${pageDefinition.base?.id} page`,
            )
              .withUserComponentId(
                pageDefinition.base?.id as string,
                'unified-page',
              )
              .withParentError(error as Error),
          ),
        );
    });
  }

  // COMPLETED
  function addContentToPage(
    pageDefinition: UnifiedPage,
    widgets: UnifiedComponentsCollection['widgets'],
    pageRef: PageRef,
  ): Promise<PageRef> {
    const addWidgetAsContent = (widget: WidgetAsContent) =>
      new Promise((resolve, reject) => {
        const { widgetGuid, preset } = widget;
        const widgetDefinition = widgets[widgetGuid as string];
        if (!widgetDefinition) {
          return reject(
            createAddUnifiedComponentsError(
              ErrorType.widgetGuidNotFound,
              `Could not find widget: ${widgetGuid} to install over page: ${pageDefinition.base?.name}`,
            ).withUserComponentId(widgetGuid as string, 'unified-widget'),
          );
        }

        addWidgetWithinNewSection(pageRef, widgetDefinition, preset)
          .then(resolve)
          .catch(reject);
      });

    return new Promise((resolve, reject) => {
      chainPromisesAsync(
        pageDefinition.content?.widgets ?? [],
        addWidgetAsContent,
      )
        .then(() => resolve(pageRef))
        .catch(reject);
    });
  }

  // COMPLETED
  function addWidgetWithinNewSection(
    pageRef: PageRef,
    widgetDefinition: UnifiedWidget,
    preset?: MainPresets,
  ): Promise<PageRef> {
    return new Promise((resolve, reject) => {
      context.platformApiMethods.editor.sections
        .addSectionToPage(
          appData,
          TOKEN,
          (newSectionRef: CompRef) =>
            addWidgetInSection(widgetDefinition, newSectionRef, preset),
          { pageRef, sectionName: widgetDefinition.base.name },
        )
        .then(() => {
          resolve(pageRef);
        })
        .catch((error: Error) => {
          reject(
            createAddUnifiedComponentsError(
              ErrorType.addingWidgetIntoSectionFailed,
              'failed to add widget within new section',
            )
              .withPageRef(pageRef, true)
              .withUserComponentId(
                (widgetDefinition.base.id as string) ??
                  widgetDefinition?.widgetId,
                'unified-widget',
              )
              .withParentError(error as Error),
          );
        });
    });
  }

  // TODO: Do not add widget when it installed as 'maxInstances' times - trigger installation failure instead.
  function addWidgetInSection(
    widgetDefinition: UnifiedWidget,
    containerRef: CompRef,
    preset?: MainPresets,
  ): Promise<CompRef> {
    return new Promise((resolve, reject) => {
      if (Object.keys(addWidgetHandler).includes(widgetDefinition.type)) {
        addWidgetHandler[widgetDefinition.type as AllowedWidget](
          context,
          appData,
          widgetDefinition,
          containerRef,
          preset,
        )
          .then(resolve)
          .catch(reject);
      } else {
        const { widgetId } = widgetDefinition;
        reject(
          createAddUnifiedComponentsError(
            ErrorType.widgetTypeNotSupported,
            `There is no implementation to support adding ${widgetDefinition.type} widget`,
          ).withUserComponentId(widgetId, 'unified-widget'),
        );
      }
    });
  }

  function addWidget(
    pageRef: PageRef,
    widgetDefinition: UnifiedWidget,
    preset?: MainPresets,
  ) {
    return new Promise<PageRef | void>((resolve, reject) => {
      if (!widgetDefinition.installation.base?.autoAdd) {
        return resolve();
      }

      const { region, defaultPreset } =
        widgetDefinition.installation.widget ?? {};

      if (region === PAGE_REGIONS.BODY) {
        addWidgetWithinNewSection(
          pageRef,
          widgetDefinition,
          preset ?? defaultPreset,
        )
          .then(resolve)
          .catch(reject);
      } else {
        resolve();
      }
    });
  }

  return { addPage, addWidget };
}
