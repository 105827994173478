import { DocumentServicesObject } from '@wix/document-services-types';
import {
  AppData,
  ComponentRef,
  WidgetViewState,
} from '@wix/editor-platform-sdk-types';
import { RESET_VIEW_STATE_EXPERIMENT } from './constants';
import { sendWidgetViewStateChangedBiEvent } from './bi';
import {
  assertComponentBelongsToApplication,
  getNestedWidgets,
  getViewStatesByCompRef,
} from './utils';
import { platformEvents } from '../../../platformEvents';
import { IExperimentsApiAdapter } from '../../utils/experimentsApiAdapter';

type RegistryValue = {
  appData: AppData;
  name: string;
  componentRef: ComponentRef;
};

declare global {
  // eslint-disable-next-line no-var
  var viewStatesRegistry: Record<string, RegistryValue>;
  // eslint-disable-next-line no-var
  var viewStatesRegistryMap: Map<string, RegistryValue>;
}

const registry = (() => {
  // TODO: get rid of globalThis once there is single instance of host-integration
  // https://jira.wixpress.com/browse/EP-4297
  if (!globalThis.viewStatesRegistry) {
    globalThis.viewStatesRegistry = {};
  }

  return globalThis.viewStatesRegistry;
})();

const registryMap = (() => {
  // TODO: get rid of globalThis once there is single instance of host-integration
  // https://jira.wixpress.com/browse/EP-4297
  if (!globalThis.viewStatesRegistryMap) {
    globalThis.viewStatesRegistryMap = new Map();
  }

  return globalThis.viewStatesRegistryMap;
})();

function getInitialState(viewStates: Record<string, WidgetViewState>) {
  if (!Object.keys(viewStates).length) {
    return undefined;
  }

  const initialState = Object.entries(viewStates).find(
    ([_stateId, viewState]) => {
      return viewState.initial;
    },
  );

  const [id, viewState] = initialState
    ? initialState
    : Object.entries(viewStates)[0];

  return {
    id,
    viewState,
  };
}

export function getViewState(
  documentServices: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
  appData: AppData,
  componentRef: ComponentRef,
) {
  if (!appData.appDefinitionId) {
    throw new Error(`appDefinitionId is required`);
  }

  assertComponentBelongsToApplication(
    documentServices,
    componentRef,
    appData.appDefinitionId!,
  );

  if (experimentsAPI.enabled(RESET_VIEW_STATE_EXPERIMENT)) {
    if (registryMap.has(componentRef.id)) {
      return registryMap.get(componentRef.id)!.name;
    }
  } else {
    if (registry[componentRef.id]) {
      return registry[componentRef.id].name;
    }
  }

  return getInitialState(getViewStatesByCompRef(documentServices, componentRef))
    ?.id;
}

export function setViewState(
  documentServices: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
  appData: AppData,
  componentRef: ComponentRef,
  stateName: string,
): void {
  if (!appData.appDefinitionId) {
    throw new Error(`appDefinitionId is required`);
  }

  assertComponentBelongsToApplication(
    documentServices,
    componentRef,
    appData.appDefinitionId,
  );

  const viewStates = getViewStatesByCompRef(documentServices, componentRef);

  if (!viewStates[stateName]) {
    return;
  }

  const registryValue = {
    name: stateName,
    appData,
    componentRef,
  };

  if (experimentsAPI.enabled(RESET_VIEW_STATE_EXPERIMENT)) {
    registryMap.set(componentRef.id, registryValue);
  } else {
    registry[componentRef.id] = registryValue;
  }

  sendWidgetViewStateChangedBiEvent({
    appDefId: appData.appDefinitionId!,
    hostAppId: appData.applicationId!,
    componentId: componentRef.id,
    modes: Object.keys(viewStates),
    rootWidget: false,
  });

  /**
   * TODO: DM types should be updated – https://github.com/wix-private/document-management/blob/master/document-services-types/src/platformObject.ts#L213
   */
  (documentServices.platform as any).livePreview.updateWidgetViewState({
    compId: componentRef.id,
    widgetViewState: { stateName, stateProps: {} },
  });

  documentServices.platform.notifyApplicationByAppDefId(
    appData.appDefinitionId,
    platformEvents.factory.viewStateChanged({ stateName, stateProps: {} }),
  );

  // Apply view state for nested widgets
  const nestedWidgets = getNestedWidgets(
    documentServices,
    appData,
    componentRef,
  );

  nestedWidgets.forEach((compRef) => {
    const childViewStates = getViewStatesByCompRef(documentServices, compRef);

    const childStateName = childViewStates[stateName]
      ? stateName
      : getInitialState(childViewStates)?.id;

    if (childStateName) {
      setViewState(
        documentServices,
        experimentsAPI,
        appData,
        compRef,
        childStateName,
      );
    }
  });
}

export function reset(experimentsAPI: IExperimentsApiAdapter) {
  if (experimentsAPI.enabled(RESET_VIEW_STATE_EXPERIMENT)) {
    registryMap.clear();
  } else {
    for (const key in registry) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete registry[key];
    }
  }
}

type ResetPredicate = (args: {
  componentRef: ComponentRef;
  state: string;
  initialState: string;
}) => boolean;

export function resetWithPredicate(
  documentServicesAPI: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
  predicate: ResetPredicate,
) {
  const values = experimentsAPI.enabled(RESET_VIEW_STATE_EXPERIMENT)
    ? Array.from(registryMap.values())
    : Object.values(registry);

  values.forEach((value) => {
    const initialState = getInitialState(
      getViewStatesByCompRef(documentServicesAPI, value.componentRef),
    )?.id;

    if (!initialState) {
      return;
    }

    if (
      predicate({
        componentRef: value.componentRef,
        state: value.name,
        initialState,
      })
    ) {
      setViewState(
        documentServicesAPI,
        experimentsAPI,
        value.appData,
        value.componentRef,
        initialState,
      );
    }
  });
}
