import {
  AppData,
  CompRef,
  DocumentServicesObject,
} from '@wix/document-services-types';
import {
  RawEditorBehavior,
  EditorBehaviorType,
  BehaviorName,
} from '@wix/editor-platform-host-integration-apis';

import { PLATFORM_PANEL_OPTIONS, SETTINGS, DASHBOARD } from './constants';
import { ResolverFunction, PlatformizedComponentData } from './types';

const settingsDataResolver: ResolverFunction<typeof SETTINGS> = (
  behavior,
  _appData,
) => {
  const url = behavior?.settingsUrl;

  if (!url) {
    return;
  }

  const defaultPanelOptions =
    PLATFORM_PANEL_OPTIONS[SETTINGS]?.panelOptions || {};

  return {
    url,
    ...defaultPanelOptions,
  };
};

const dashboardDataResolver: ResolverFunction<typeof DASHBOARD> = (
  behavior,
  appData,
) => {
  const url = appData?.components?.find(
    (comp) => comp.componentId === behavior?.dashboardPageComponentId,
    // @ts-expect-error
  )?.data?.route;
  const defaultPanelOptions =
    PLATFORM_PANEL_OPTIONS[DASHBOARD]?.panelOptions || {};

  if (!url) {
    return;
  }

  return {
    url,
    ...defaultPanelOptions,
  };
};

const dataResolvers = {
  [SETTINGS]: settingsDataResolver,
  [DASHBOARD]: dashboardDataResolver,
};

function getPanelData<T extends BehaviorName>(
  panelType: T,
  behavior: RawEditorBehavior[T],
  appData: AppData,
) {
  const resolver = dataResolvers[panelType] as ResolverFunction<T> | undefined;
  if (resolver === undefined) {
    return;
  }

  return resolver(behavior, appData);
}

export function getComponentEditorBehavior(
  documentServices: DocumentServicesObject,
  componentRef: CompRef,
): EditorBehaviorType | null {
  const compData = documentServices.components.data.get(componentRef) || {};
  const appDefinitionId = compData?.appDefinitionId;
  const widgetId = compData?.widgetId;

  if (!appDefinitionId) {
    return null;
  }

  const appData =
    documentServices.platform.getAppDataByAppDefId(appDefinitionId);

  if (!appData?.components) {
    throw new Error(
      'Component connected to an app without component data or the app is not installed',
    );
  }

  const platformizedComponent = appData.components.find(
    (component: any) => component.componentId === widgetId,
  ) as PlatformizedComponentData;

  if (!platformizedComponent || !platformizedComponent?.data?.behaviors) {
    return null;
  }

  const editorBehavior = Object.entries(
    platformizedComponent.data.behaviors,
  ).reduce((acc, [behaviorName, behavior]) => {
    return {
      ...acc,
      [behaviorName]: {
        componentRef,
        panelOptions: getPanelData(
          behaviorName as keyof RawEditorBehavior,
          behavior,
          appData,
        ),
      },
    };
  }, {});

  // editorBehavior.upgrade = {}; // can be defined here
  //  is the place to add common behavior to all components
  return editorBehavior;
}
