import { AppInstallOption } from '@wix/editor-platform-host-integration-apis';
import { reportBi } from '../../../essentials';
import { getGUID } from '../../utils/guid';
import { BI_EVENT_IDS } from './constants';
import { UnifiedComponentsError } from './errors';

type ExtraDataForBIsMap = {
  NAVIGATE_TO_DEVCENTER: { appDefinitionId: string };
  NEW_COMPONENTS_INSTALL: {
    appDefinitionId: string;
    components_list: any[];
    numberInstalledComponents: number;
  };
  INSTALL_CODE_PACKAGES: {
    appDefinitionId: string;
  };
  PLATFORM_RUN_SCRIPTS: {
    appDefinitionId: string;
  };
  PLATFORM_ERROR: {
    errorCode: string;
    errorData: string;
    errorDetails: string;
    errorMessage: string;
    errorType: string;
  };
};

type ExtraDataForEvent<T extends keyof typeof BI_EVENT_IDS> =
  T extends keyof ExtraDataForBIsMap ? ExtraDataForBIsMap[T] : {};

type WithBI = <T extends keyof typeof BI_EVENT_IDS, V>(
  biEvent: T,
  extra: ExtraDataForEvent<T>,
  func: () => Promise<V>,
) => Promise<V>;

type ReportBI = <T extends keyof typeof BI_EVENT_IDS>(
  biEvent: T,
  extra: ExtraDataForEvent<T>,
) => void;

export type BIReporter = {
  withBI: WithBI;
  reportBI: ReportBI;
};

export function getBIReporter(
  appDefIds: string[],
  options?: Record<string, AppInstallOption>,
): BIReporter {
  const installationId = getGUID();
  const editorType = window.commonConfig?.host?.toLowerCase();
  const fallbackAppDefIdForOrigin = appDefIds.findLast(
    (appDefId: string) => options?.[appDefId]?.origin,
  );

  const getOriginInfo = (appDefinitionId?: string): string => {
    if (appDefinitionId) {
      const appOptions = options?.[appDefinitionId];
      if (appOptions) {
        const originByOptions =
          appOptions.origin?.info?.type ||
          appOptions.origin?.info?.appDefinitionId;

        if (originByOptions) {
          return originByOptions;
        }
      }
    }

    if (
      fallbackAppDefIdForOrigin &&
      appDefinitionId !== fallbackAppDefIdForOrigin
    ) {
      return getOriginInfo(fallbackAppDefIdForOrigin);
    }

    return '';
  };

  const reportProcess = <V>(
    evid: number,
    func: () => Promise<V>,
    extra?: any,
  ) => {
    const startTime = Date.now();
    let endStatus = 'end';
    const origin = getOriginInfo(
      Array.isArray(extra?.appDefinitionId)
        ? extra?.appDefinitionId[-1]
        : extra?.appDefinitionId,
    );

    reportEvent(evid, {
      duration: 0,
      type: 'start',
      ...extra,
    });
    return new Promise<V>((resolve, reject) => {
      func()
        .then(resolve)
        .catch((error) => {
          reportEvent(
            BI_EVENT_IDS.PLATFORM_ERROR,
            (error as UnifiedComponentsError)?.getExtraDetailsForBI?.(),
          );
          reject(error);
          endStatus = 'error';
        })
        .finally(() => {
          reportEvent(evid, {
            duration: Date.now() - startTime,
            type: endStatus,
            ...extra,
          });
        });
    });
  };

  const reportEvent = (evid: number, extra?: any) => {
    reportBi(evid, {
      installationId,
      origin,
      editorType,
      ...extra,
    });
  };

  return {
    withBI: <T extends keyof typeof BI_EVENT_IDS, V>(
      biEvent: T,
      extra: ExtraDataForEvent<T>,
      func: () => Promise<V>,
    ): Promise<V> => {
      const evid = BI_EVENT_IDS[biEvent];
      switch (biEvent) {
        case 'PLATFORM_INSTALLATION':
          return reportProcess(evid, func, { appDefinitionId: appDefIds });
        case 'PROVISION_APPS':
          return reportProcess(evid, func, {
            appDefinitionId: appDefIds,
            name: func.name,
          });
        case 'NEW_COMPONENTS_INSTALL':
          const { components_list } =
            extra as ExtraDataForEvent<'NEW_COMPONENTS_INSTALL'>;
          return reportProcess(evid, func, {
            ...extra,
            numberTotalComponents: components_list.length,
            components_list: JSON.stringify(components_list),
            name: func.name,
          });
        default:
          return reportProcess(evid, func, { ...extra, name: func.name });
      }
    },
    reportBI: <T extends keyof typeof BI_EVENT_IDS>(
      biEvent: T,
      extra: ExtraDataForEvent<T>,
    ) => {
      // Currently relevant only to NAVIGATE_TO_DEVCENTER event.
      // If you add another event - please use similar conditions structure as in `withBI`
      // to provide the relevant extras which should not be given by the user
      const evid = BI_EVENT_IDS[biEvent];
      return reportEvent(evid, extra);
    },
  };
}
