import {
  AppData,
  AppUnifiedComponentData,
  ComponentRemovedHookFunc,
  ComponentRemovedHookParams,
  DocumentServicesObject,
} from '@wix/document-services-types';
import { BaseInstallation } from '@wix/ambassador-devcenter-components-v1-component/types';
import { getComponentId, getAppDefId } from '../../utils/component';
import { PlatformApiMethods } from '../../../types/platformApi';

let _isCurrentlyUninstalling = false;

export const getBaseInstallationConfiguration = (
  componentId: string,
  appData: AppData,
): BaseInstallation | undefined => {
  const componentData = appData.components?.find(
    (component) => component.componentId === componentId,
  )?.data;
  return (componentData as AppUnifiedComponentData)?.installation?.base;
};

export const isComponentEssential = (
  componentId: string,
  appData: AppData,
): boolean => {
  const baseInstallationConfig = getBaseInstallationConfiguration(
    componentId,
    appData,
  );
  return !!baseInstallationConfig?.essential;
};

export const isPageEssential = (
  documentServicesAPI: DocumentServicesObject,
  pageId: string,
) => {
  const appDefinitionId =
    documentServicesAPI.pages.data.get(pageId)?.appDefinitionId;
  if (appDefinitionId) {
    const appData =
      documentServicesAPI.platform.getAppDataByAppDefId(appDefinitionId);
    if (appData) {
      const pageComponents =
        documentServicesAPI.components.getAllComponents(pageId);
      const isEssentialPage = pageComponents.some((compRef) => {
        const componentId = getComponentId(
          documentServicesAPI.components.data.get(compRef),
        );
        return componentId && isComponentEssential(componentId, appData);
      });
      return isEssentialPage;
    }
  }
  return false;
};

const uninstallApp = async (
  platformApiMethods: PlatformApiMethods,
  appData: AppData,
  shouldOpenConfirmationPanel: boolean,
) => {
  if (!_isCurrentlyUninstalling) {
    _isCurrentlyUninstalling = true;
    await platformApiMethods.document.application.uninstall(
      appData as AppData,
      'token',
      {
        openConfirmation: shouldOpenConfirmationPanel,
      },
    );
    _isCurrentlyUninstalling = false;
  }
};

export const componentRemovedHook = (
  platformApiMethods: PlatformApiMethods,
  documentServicesAPI: DocumentServicesObject,
): ComponentRemovedHookFunc => {
  return async ({ dataItem }: ComponentRemovedHookParams) => {
    const componentId = getComponentId(dataItem);
    const appDefId = getAppDefId(dataItem);
    if (componentId && appDefId) {
      const appData =
        documentServicesAPI.platform.getAppDataByAppDefId(appDefId);
      if (appData && isComponentEssential(componentId, appData)) {
        await uninstallApp(platformApiMethods, appData, false);
      }
    }
  };
};
