export function chainPromisesAsync<T>(
  array: T[],
  functionToApply: (item: T) => Promise<any>,
) {
  return array.reduce(
    (previousPromise, currentItem) =>
      previousPromise.then(() => functionToApply(currentItem)),
    Promise.resolve(),
  );
}
