import { DocumentServicesObject } from '@wix/document-services-types';
import {
  createUnifiedComponentsInstallationError,
  UnifiedComponentsInstallationErrorCode as ErrorType,
  UnifiedComponentsInstallationError,
} from '../errors';
import { chainPromisesAsync } from '../utils';
import { getBIReporter } from '../bi';
import { AddAppData, AppData } from '../../../../types/unifiedComponents';
import {
  InstallOptions,
  InstallCallbacks,
} from '@wix/editor-platform-host-integration-apis';
import { PlatformContext } from '../../../../types/platformApi';
import { installSingleUnifiedComponentsApp } from './installSingleApp';
import { APP_SCHEMA_VERSIONS } from '../constants';

type ProvisionedApps = {
  nonUnifiedAppsData: AppData[];
  unifiedAppsData: AppData[];
};

type AfterOldInstallationAppsData = {
  nonUnifiedAppsData: AddAppData[];
  unifiedAppsData: AppData[];
};

type AllAppsDataAfterInstallation = {
  nonUnifiedAppsData: AddAppData[];
  unifiedAppsData: AddAppData[];
};

// TODO: Remove ts-except-error when types will be up to date (EP-3956)
// TODO fix to type of https://jira.wixpress.com/browse/WOSDC-1302
function isUnifiedComponentsApp(appData: AppData) {
  return (
    // @ts-expect-error
    appData?.appFields?.newEditorSchemaVersion ===
    APP_SCHEMA_VERSIONS.EXTERNAL_UNIFIED_COMPONENT
  );
}

export function installApps(
  context: PlatformContext,
  documentServices: DocumentServicesObject,
  appDefIds: string[],
  installOptions?: InstallOptions,
  callbacks?: InstallCallbacks,
): Promise<void> {
  const { appsOptions } = installOptions ?? {};
  const biReporter = getBIReporter(appDefIds, appsOptions);

  function triggerFinishInstallAllAppsCallback(
    provisionedApps: AllAppsDataAfterInstallation,
  ) {
    return new Promise<void>((resolve) => {
      callbacks?.finishAllCallback?.([
        ...provisionedApps.nonUnifiedAppsData,
        ...provisionedApps.unifiedAppsData,
      ]);
      resolve();
    });
  }

  function installAllUnifiedComponentsApps(
    provisionedApps: AfterOldInstallationAppsData,
  ) {
    const resultedAddAppsData: AddAppData[] = [];
    const updateAppDataAfterInstallation = (appData: AppData) =>
      new Promise<void>((resolve, reject) =>
        installSingleUnifiedComponentsApp(
          { ...context, documentServices },
          appData,
          biReporter,
          appsOptions?.[appData.appDefinitionId],
          callbacks?.singleAppCallback,
        )
          .then((addAppData: AddAppData) => {
            resultedAddAppsData.push(addAppData);
            resolve();
          })
          .catch(reject),
      );

    return new Promise<AllAppsDataAfterInstallation>((resolve, reject) =>
      chainPromisesAsync(
        provisionedApps.unifiedAppsData,
        updateAppDataAfterInstallation,
      )
        .then(() =>
          resolve({
            nonUnifiedAppsData: provisionedApps.nonUnifiedAppsData,
            unifiedAppsData: resultedAddAppsData,
          }),
        )
        .catch(reject),
    );
  }

  function installNonUnifiedApps(provisionedApps: ProvisionedApps) {
    return new Promise<AfterOldInstallationAppsData>((resolve, reject) => {
      const nonUnifiedAppDefIds = provisionedApps.nonUnifiedAppsData.map(
        ({ appDefinitionId }) => appDefinitionId,
      );

      if (nonUnifiedAppDefIds.length > 0) {
        documentServices.platform.addApps(nonUnifiedAppDefIds, {
          ...appsOptions,
          singleAppCallback: callbacks?.singleAppCallback,
          finishAllCallback: (appsData: AddAppData[]) =>
            resolve({
              nonUnifiedAppsData: appsData,
              unifiedAppsData: provisionedApps.unifiedAppsData,
            }),
          onError: (error: Error, _errName: string, appDefinitionId: string) =>
            reject(
              createUnifiedComponentsInstallationError(
                ErrorType.nonUnifiedInstallationFailed,
                'Error occur in non-unified applications installation process',
              )
                .withAppDefIds(appDefinitionId)
                .withParentError(error as Error),
            ),
        });
      } else {
        resolve(provisionedApps as AfterOldInstallationAppsData);
      }
    });
  }

  function validateIfNoAppProvisioned(provisionedApps: ProvisionedApps) {
    return new Promise<ProvisionedApps>((resolve, reject) => {
      if (
        !provisionedApps ||
        (provisionedApps.nonUnifiedAppsData.length === 0 &&
          provisionedApps.unifiedAppsData.length === 0)
      ) {
        reject(
          createUnifiedComponentsInstallationError(
            ErrorType.noAppRecivedFromProvision,
            'None of the apps could be provisioned',
          ).withAppDefIds(appDefIds),
        );
      }
      resolve(provisionedApps);
    });
  }

  function classifyProvisionedApps(appsData: AppData[]) {
    return new Promise<ProvisionedApps>((resolve) =>
      resolve(
        appsData.reduce(
          (acc: ProvisionedApps, appData: AppData) => {
            if (isUnifiedComponentsApp(appData)) {
              acc.unifiedAppsData.push(appData);
            } else {
              acc.nonUnifiedAppsData.push(appData);
            }
            return acc;
          },
          { nonUnifiedAppsData: [], unifiedAppsData: [] },
        ),
      ),
    );
  }

  function provisionApps(): Promise<AppData[]> {
    return biReporter.withBI(
      'PROVISION_APPS',
      {},
      function provisionAndClassifyApps() {
        return new Promise<AppData[]>((resolve, reject) => {
          documentServices.platform.installationFlow
            .provisionApps(appDefIds, appsOptions ?? {})
            .then((appsData) => resolve(appsData as AppData[]))
            .catch((error: Error) =>
              reject(
                createUnifiedComponentsInstallationError(
                  ErrorType.provisionAppsFailed,
                  'Could not provision apps',
                )
                  .withAppDefIds(appDefIds)
                  .withParentError(error),
              ),
            );
        });
      },
    );
  }

  function installAllApps() {
    return biReporter.withBI(
      'PLATFORM_INSTALLATION',
      {},
      () =>
        new Promise<void>((resolve, reject) => {
          provisionApps()
            .then(classifyProvisionedApps)
            .then(validateIfNoAppProvisioned)
            .then(installNonUnifiedApps)
            .then(installAllUnifiedComponentsApps)
            .then(triggerFinishInstallAllAppsCallback)
            .then(resolve)
            .catch((error: UnifiedComponentsInstallationError) => {
              callbacks?.onError?.(
                error,
                error.name,
                error.appDefIds ?? appDefIds.toString(),
              );
              reject(error);
            });
        }),
    );
  }

  return installAllApps();
}
